import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278 135.5C278 121.969 288.969 111 302.5 111C316.031 111 327 121.969 327 135.5V290.5C327 290.611 326.999 290.722 326.998 290.833C326.999 291.055 327 291.277 327 291.5C327 294.029 326.905 296.535 326.718 299.015C326.847 303.675 326.888 308.512 326.929 313.295V313.295L326.929 313.298C327.056 328.263 327.178 342.696 330 349.5C333.464 357.851 342.736 362.833 349.763 366.608C358.062 371.066 363.229 373.843 352 378.5C333.975 385.976 301.241 382.884 288.024 369.987C271.49 382.546 250.865 390 228.5 390C174.1 390 130 345.9 130 291.5C130 237.1 174.1 193 228.5 193C246.544 193 263.456 197.852 278 206.323V135.5ZM277 291.5C277 318.286 255.286 340 228.5 340C201.714 340 180 318.286 180 291.5C180 264.714 201.714 243 228.5 243C255.286 243 277 264.714 277 291.5ZM355.5 352C364.06 352 371 345.06 371 336.5C371 327.94 364.06 321 355.5 321C346.94 321 340 327.94 340 336.5C340 345.06 346.94 352 355.5 352Z"
          fill="#1D1D1D"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
